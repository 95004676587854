import { useRouter } from 'next/router'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

const CourseContext = createContext({
  courseDetails: {},
  setCourseDetails: () => {},
  activeContent: {},
  setActiveContent: () => {},
  courseId: '',
  setCourseId: () => {},
  dataForNextAndPrevious: [],
  setDataForNextAndPrevious: () => {},
  activeKeys: [],
  addActiveKey: (key) => {},
  removeActiveKey: (key) => {},
  loadPreviouslyExploredModules: () => {},
  loadPreviouslyViewedContent: () => {},
  setPreviouslyViewedContent: (contentId) => {},
  access: false,
  setAccess: () => {},
})

const CourseDetailsProvider = ({ children }) => {
  const [courseDetails, setCourseDetails] = useState({})
  const [courseId, setCourseId] = useState('')
  const [activeContent, setActiveContent] = useState(null)
  const [activeKeys, setActiveKeys] = useState([])
  const [dataForNextAndPrevious, setDataForNextAndPrevious] = useState()
  const [access, setAccess] = useState(false)

  const router = useRouter()

  const addActiveKey = useCallback(
    (key) => {
      if (courseId === '') return
      setActiveKeys((curActiveKeys) => {
        const nextActiveKeys = [...curActiveKeys, key]
        localStorage.setItem(
          'activeKeys_' + courseId,
          JSON.stringify(nextActiveKeys)
        )
        return nextActiveKeys
      })
    },
    [courseId]
  )

  const removeActiveKey = useCallback(
    (key) => {
      if (courseId === '') return
      setActiveKeys((curActiveKeys) => {
        const nextActiveKeys = curActiveKeys.filter((k) => k !== key)
        localStorage.setItem(
          'activeKeys_' + courseId,
          JSON.stringify(nextActiveKeys)
        )
        return nextActiveKeys
      })
    },
    [courseId]
  )

  const loadPreviouslyViewedContent = useCallback(() => {
    // load previously viewed content
    const activeContentIdFromLS = localStorage.getItem(
      'activeContentId_' + courseId
    )

    if (activeContentIdFromLS) {
      const contentId = JSON.parse(activeContentIdFromLS)
      router.query.contentId = contentId
      router.push({ query: router.query })
    }
  }, [courseId])

  const loadPreviouslyExploredModules = useCallback(() => {
    const activeKeysFromLS = localStorage.getItem('activeKeys_' + courseId)
    if (activeKeysFromLS) {
      setActiveKeys(JSON.parse(activeKeysFromLS))
    }
  }, [courseId])

  const setPreviouslyViewedContent = useCallback(
    (contentId) => {
      localStorage.setItem(
        'activeContentId_' + courseId,
        JSON.stringify(contentId)
      )
    },
    [courseId]
  )

  useEffect(() => {
    if (activeContent?._id) {
      setPreviouslyViewedContent(activeContent._id)
    }
  }, [activeContent])

  const contextValue = useMemo(
    () => ({
      courseDetails,
      setCourseDetails,
      activeContent,
      setActiveContent,
      dataForNextAndPrevious,
      setDataForNextAndPrevious,
      courseId,
      setCourseId,
      activeKeys,
      addActiveKey,
      removeActiveKey,
      loadPreviouslyExploredModules,
      loadPreviouslyViewedContent,
      setPreviouslyViewedContent,
      access,
      setAccess,
    }),
    [
      addActiveKey,
      removeActiveKey,
      loadPreviouslyViewedContent,
      loadPreviouslyExploredModules,
      setPreviouslyViewedContent,
      courseDetails,
      courseId,
      activeContent,
      activeKeys,
      dataForNextAndPrevious,
      access,
    ]
  )

  return (
    <CourseContext.Provider value={contextValue}>
      {children}
    </CourseContext.Provider>
  )
}
export default CourseDetailsProvider
export const useCourseDetails = () => useContext(CourseContext)
